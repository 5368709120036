import React, {useContext, useMemo, useState} from "react";
import {useStaticQuery, graphql, Link} from "gatsby";
import XCol from "../ui/XCol";
import XText from "../ui/XText";
import XPush from "../ui/XPush";
import {stationsStyles as styles} from "../pages.treat";
import {IconCheckmark} from "../ui/Icon";
import Strip from "../ui/Strip";
import Img from "../components/Img";
import MainLayout from "../templates/MainLayout";
import {routeToStation} from "../routes";
import {useIsDemo, useTaskKeys, DBCtx, parseB64} from "../models/session";
import PlainButton from "../ui/PlainButton";
import Input from "../ui/Input";
import {arrayBufferBlobToObjectURL} from "../components/PhotoInput";

const StationTile = ({station, taskKeys}) => {
  const isDone = useMemo(
    () => station.chapters.every((c) => c.tasks.every((t) => taskKeys.has(t._key))),
    [station, taskKeys]
  );
  return (
    <XCol
      as={Link}
      to={routeToStation(station)}
      relative
      rounded="lg"
      elevation="mdHeavy"
      cropContent
    >
      <div style={{paddingBottom: "33%"}} />
      <Img asset={station.bgImg} mode="cover" width={600} />
      {isDone && (
        <XCol absolute inset="full" pa={4} align="end" justify="end" style={{zIndex: 1}}>
          <XCol pa={2} bg="green700" rounded="full">
            <IconCheckmark color="white" />
          </XCol>
        </XCol>
      )}
      <XCol
        absolute
        inset="full"
        pa={4}
        className={isDone ? styles.tilePastBox : styles.tilePresentBox}
      >
        <XPush />
        <XCol sp={1}>
          <XText color="green200" preset="uppercase" size="xs">
            Station {station.order}
          </XText>
          <XText preset="bold" size="xxl" color="white">
            {station.title}
          </XText>
        </XCol>
      </XCol>
    </XCol>
  );
};

const query = graphql`
  {
    sanityGeneral(_id: {eq: "general"}) {
      stationsHeader
    }
    allSanityStation(sort: {fields: order}) {
      nodes {
        order
        title
        bgImg {
          asset {
            url
          }
        }
        slug {
          current
        }
        chapters {
          tasks {
            _key
          }
        }
      }
    }
  }
`;

const arrayBufferToBase64 = (buff) => btoa(String.fromCharCode(...new Uint8Array(buff)));

const SubmitForm = ({stations, taskKeys}) => {
  const isDone = useMemo(
    () =>
      stations.every((station) =>
        station.chapters.every((c) => c.tasks.every((t) => taskKeys.has(t._key)))
      ),
    [stations, taskKeys]
  );
  const db = useContext(DBCtx);
  const [name, setName] = useState("");
  const [error, setError] = useState("");
  const [state, setState] = useState("init");

  const handleSubmit = (e) => {
    e.preventDefault();
    setState("loading");
    setError(null);
    return Promise.all([...taskKeys].map((key) => db.get(key)))
      .then((tasks) => {
        const email = parseB64(db.sessionId);
        if (!email) throw new Error("Ungültige Session. Bitte den Startlink überprüfen");
        const formData = {group: name, email};
        tasks.forEach(({type, value, stationNumber, taskNumber, chapterNumber}) => {
          const fieldName = `task_${stationNumber}_${chapterNumber}_${taskNumber}`;
          if (type === "text") {
            formData[fieldName] = {type: "text", value};
          } else {
            formData[fieldName] = {
              type: "file",
              value: {base64: arrayBufferToBase64(value.blob), type: value.type},
            };
          }
        });

        return fetch(`${process.env.GATSBY_NETLIFY_HOST}/.netlify/functions/send-mail`, {
          method: "POST",
          body: JSON.stringify(formData),
          headers: {
            "Content-Type": "application/json",
          },
        }).then((res) => {
          if (res.status < 400) {
            return res.json().then((content) => {
              setState("done");
            });
          } else {
            return res.text().then((err) => {
              return Promise.reject(new Error(err));
            });
          }
        });
      })
      .catch((err) => {
        setState("init");
        setError(err ? err.message : "Some error happened");
      });
  };

  const shownError =
    error || (!isDone ? "Achtung! Es wurden noch nicht alle Felder ausgefüllt." : null);

  return (
    <XCol sp={2} style={{width: "100%"}} border="green500" bg="green300" px={3} py={2} rounded="lg">
      {shownError && (
        <XCol rounded="md" bg="red" px={3} py={2}>
          <XText color="white" preset="bold">
            {shownError}
          </XText>
        </XCol>
      )}
      {state === "done" ? (
        <XText preset="bold" align="center">
          Ergebnisse wurden abgesandt!
        </XText>
      ) : (
        <XCol sp={3} as="form" onSubmit={handleSubmit} align="start">
          <XText>Gruppenname</XText>
          <Input required value={name} onChange={(e) => setName(e.target.value)} autoFocus />
          <PlainButton type="submit" disabled={state !== "init"}>
            Absenden
          </PlainButton>
        </XCol>
      )}
    </XCol>
  );
};

const SubmitResults = ({stations, taskKeys}) => {
  const isDemo = useIsDemo();
  const [isOpen, setOpen] = useState(false);
  if (isDemo) {
    return (
      <XCol sp={1} align="center">
        <XText preset="bold" color="green600" size="sm">
          Sie befinden sich im Demo Modus. Resultate können nicht versendet werden.
        </XText>
        <PlainButton to="/generate-id">Link erstellen.</PlainButton>
      </XCol>
    );
  } else {
    if (taskKeys.size === 0) return null;
    return (
      <XCol align="start" sp={4}>
        <PlainButton onClick={() => setOpen(!isOpen)} active={isOpen}>
          Resultate einsenden...
        </PlainButton>
        {isOpen && <SubmitForm stations={stations} taskKeys={taskKeys} />}
      </XCol>
    );
  }
};

const Inner = () => {
  const {
    allSanityStation: {nodes: stations},
    sanityGeneral: labels,
  } = useStaticQuery(query);
  const [keys] = useTaskKeys();
  const taskKeys = useMemo(() => new Set(keys), [keys]);
  return (
    <Strip pt={4} pb={5} sp={5} fillParent size="xs">
      <XText preset="bold" color="green800" size="lg">
        {labels.stationsHeader}
      </XText>
      <XCol sp={3}>
        {stations.map((station) => (
          <StationTile key={station.slug.current} station={station} taskKeys={taskKeys} />
        ))}
      </XCol>
      <XCol pt={5}>
        <SubmitResults stations={stations} taskKeys={taskKeys} />
      </XCol>
    </Strip>
  );
};

const Stationen = (props) => {
  return (
    <MainLayout {...props}>
      <Inner />
    </MainLayout>
  );
};

export default Stationen;
