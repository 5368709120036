import React from "react";
import cx from "../cx";
import {iconStyles} from "./icon.treat";
import uiClasses from "./ui.treat";

import {ReactComponent as CheckmarkSvg} from "./../icons/checkmark.svg";
import {ReactComponent as ChevronUpSvg} from "./../icons/chevron-up.svg";
import {ReactComponent as StarSvg} from "./../icons/star.svg";
import {ReactComponent as RewindSvg} from "./../icons/o-rewind.svg";
import {ReactComponent as PauseSvg} from "./../icons/o-pause.svg";
import {ReactComponent as PlaySvg} from "./../icons/o-play.svg";
import {ReactComponent as CopySvg} from "./../icons/copy.svg";
import {ReactComponent as HeadphonesSvg} from "./../icons/headphones.svg";

const makeComponent = (SvgComp, fallbackAlt) =>
  React.forwardRef(({className, size = "md", color, alt, ...rest}, ref) => (
    <div
      ref={ref}
      className={cx(
        iconStyles.container.base,
        iconStyles.container.bySize[size],
        color && uiClasses.color[color],
        className
      )}
      {...rest}
    >
      <SvgComp className={iconStyles.svg} alt={alt || fallbackAlt} />
    </div>
  ));

export const IconCheckmark = makeComponent(CheckmarkSvg, "Checkmark");
export const IconCopy = makeComponent(CopySvg, "Copy");
export const IconChevronUp = makeComponent(ChevronUpSvg, "Chevron Up");
export const IconStar = makeComponent(StarSvg, "Star");
export const IconRewind = makeComponent(RewindSvg, "Rewind");
export const IconHeadphones = makeComponent(HeadphonesSvg, "Headphones");
export const IconPlay = makeComponent(PlaySvg, "Play");
export const IconPause = makeComponent(PauseSvg, "Pause");
